<template>
  <v-container fluid tag="section" class="parent-container">
    <div class="container-content">
      <div
        v-if="
          isNew &&
          !$userPermissions.getIsSuperAdmin() &&
          !$userPermissions.getBrandAccountManager()
        "
      >
        <div class="verification-alert-container" v-if="!isAccountVerified">
          <div>
            <div>
              Your company profile needs to be verified to create collections
              for a brand.
            </div>
            <div style="margin-left: -8px">
              <request-verification-button
                @request-verification="requestVerificationForAccount"
              />
            </div>
          </div>
          <md-divider class="mb-5" />
        </div>
        <v-radio-group v-model="brandSelected" row>
          <v-radio color="#47C0BE" :value="false" id="forMe" label="For Myself" />
          <v-radio
            :disabled="!isAccountVerified"
            id="foraBrand"
            color="#47C0BE"
            :value="true"
            label="For a Brand"
          />
        </v-radio-group>
      </div>
      <v-form ref="collectionDetails" class="mt-5">
        <div
          v-if="
            !$userPermissions.getIsSuperAdmin() &&
            !$userPermissions.getBrandAccountManager() &&
            isNew &&
            brandSelected
          "
          class="md-layout-item md-small-size-100"
        >
          <v-autocomplete
            ref="brandAutocomplete"
            class="selection-field"
            clearable
            :search-input.sync="search"
            v-model="model.organizationRegistry"
            :items="globalRegistryList"
            item-text="name"
            item-value="id"
            label="Brand Name"
            :hide-no-data="loading"
            :loading="loading"
            outlined
            :rules="organizationValidationError"
          >
            <template v-slot:prepend-item>
              <span class="span-label">
                <a href="#" @click.prevent="handleClick" class="clickable-link">
                  I'm unable to find the business
                </a>
              </span>
            </template>
            <template v-slot:item="{ item }">
              <span class="list-content">{{ item.name }}</span>
            </template>
            <template v-slot:selection="{ item }">
              <span>{{ item.name }}</span>
            </template>
            <template v-slot:append-item>
              <infinite-loading
                ref="InfiniteLoading"
                @infinite="fetchMore"
                :key="`infinite-toggle-${searchName}`"
              >
                <div slot="no-more" v-if="globalRegistryList.length">
                  No more data
                </div>
                <div slot="no-more" v-if="!globalRegistryList.length">
                  No data available
                </div>
                <div slot="no-results" v-if="globalRegistryList.length">
                  No more data
                </div>
                <div slot="no-results" v-if="!globalRegistryList.length">
                  No data available
                </div>
              </infinite-loading>
            </template>
          </v-autocomplete>
          <organization-request
            :active="organizationRequesting"
            @close="organizationRequesting = false"
            class="organization-request-label"
          />
        </div>
        <div class="md-layout-item md-small-size-100">
          <v-text-field
            v-model="collectionName"
            id="collectionName"
            label="Collection Name"
            :rules="rules.collectionNameRules"
          ></v-text-field>
        </div>
        <div v-if="!isAccountVerified && isNew">
          <div>Remaining collection count: {{ remainingCollectionCount }}</div>
          <div
            v-if="
              remainingCollectionCount !== null && remainingCollectionCount <= 0
            "
            style="color: #ef5753"
          >
            You have reached maximum allowed collection count as an unverified
            user. Please verify your account to create more collections.
          </div>
        </div>
        <div>
          <md-card-actions style="padding: 8px 0px">
            <ui-button
              id="saveButton"
              class="md-raised"
              @click="save()"
              title="Save"
              :disabled="
                remainingCollectionCount <= 0 && !isAccountVerified && isNew
              "
            />
          </md-card-actions>
        </div>
      </v-form>
    </div>
  </v-container>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import restAdapter from "../../restAdapter";
import auth from "../../auth";
import notification from "../../notification";
import routes from "../../router/routes";
import InfiniteLoading from "vue-infinite-loading";
import debounce from "lodash/debounce";
import RequestVerificationButton from "@/components/RequestVerificationButton.vue";
import store from "@/store";

export default {
  name: "CreateCollection",
  components: {
    InfiniteLoading,
    RequestVerificationButton,
  },
  data: () => ({
    userOrganizationId: null,
    isNew: true,
    collectionCount: null,
    model: {
      organizationRegistry: null,
    },
    globalRegistryList: [],
    organizationType: "brand",
    rules: {
      collectionNameRules: [
        (value) => !!value || "Collection name is required",

        (value) =>
          (value && value.length <= 200) ||
          "Collection name must not exceed 200 characters",
      ],
    },
    collectionId: null,
    organizationRequesting: false,
    brandSelected: false,
    collectionName: null,
    searchName: "",
    loading: false,
  }),
  props: {
    collectionName: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.collectionId = this.$route.params.id;
    this.isNew = !this.$route.params.id;
    this.userOrganizationId = auth.user.account.organization_registry_id;
    if (this.isNew) {
      this.getGlobalRegistryList();
    }
    this.getCollectionCount();
  },
  watch: {
    brandSelected: {
      handler: function (value) {
        if (value) {
          this.model.organizationRegistry = null;
        }
      },
    },
  },
  computed: {
    search: {
      get() {
        return this.searchName;
      },
      set(searchInput) {
        if (this.searchName !== searchInput) {
          this.searchName = searchInput;
          this.loading = true;
          this.debounceSearch();
        }
      },
    },
    remainingCollectionCount() {
      const maxAllowed = 5;
      if (this.collectionCount === null) {
        return null;
      } else {
        return Math.max(maxAllowed - this.collectionCount, 0);
      }
    },
    organizationValidationError() {
      return [
        (v) => {
          if (!this.model.organizationRegistry) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
    isAccountVerified() {
      return auth.user.account?.verified;
    },
  },
  methods: {
    fetchMore($state) {
      let url = "/api/organization_registry";
      url +=
        "?page=" +
        (this.currentPage + 1) +
        "&type=" +
        this.organizationType +
        "&name=" +
        (this.searchName ? this.searchName : "");
      restAdapter.get(url).then((response) => {
        if (response.data.data.length) {
          this.globalRegistryList = this.globalRegistryList.concat(
            response.data.data
          );
          this.currentPage++;
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    debounceSearch: debounce(function () {
      this.getGlobalRegistryList();
    }, 600),
    getGlobalRegistryList() {
      this.currentPage = 1;
      let url = "/api/organization_registry";
      url +=
        "?page=" +
        this.currentPage +
        "&type=" +
        this.organizationType +
        "&name=" +
        (this.searchName ? this.searchName : "");
      restAdapter.get(url).then((response) => {
        this.loading = false;
        this.globalRegistryList = response.data.data;
      });
    },
    save() {
      if (!this.$refs.collectionDetails.validate()) {
        return;
      }
      if (this.isNew) {
        this.createCollection();
      } else {
        this.updateCollection();
      }
    },
    async getCollectionCount() {
      try {
        const response = await restAdapter.get("/api/collections/count");
        this.collectionCount = response.data ? response.data.count : 0;
      } catch (error) {
        console.error("Error fetching collection count:", error);
        this.collectionCount = 0;
      }
    },
    createCollection() {
      restAdapter
        .post(`/api/collection`, {
          name: this.collectionName,
          brand_id: this.brandSelected
            ? this.model.organizationRegistry
            : this.userOrganizationId,
        })
        .then((response) => {
          notification.success("Collection created successfully");
          this.$router.push({
            name: routes.COLLECTION,
            params: { id: response.data.collection.id },
          });
        })
        .catch((error) => {
          notification.error("Collection created unsuccessfully");
        });
    },
    openOrganizationRequest() {
      this.organizationRequesting = true;
    },
    handleClick() {
      this.$refs.brandAutocomplete.blur();
      this.openOrganizationRequest();
    },
    updateCollection() {
      restAdapter
        .put(`/api/collection/${this.collectionId}`, {
          name: this.collectionName,
        })
        .then((response) => {
          notification.success("Collection updated successfully");
          this.$emit("update-event", this.collectionName);
        })
        .catch((error) => {
          notification.error("Collection updated unsuccessfully");
        });
    },
    requestVerificationForAccount() {
      const id = auth.user.account?.organization_registry_id;
      if (!id) {
        return;
      }
      restAdapter
        .post(`/api/organization_verification_requests/${id}`)
        .then(() => {
          store.commit("SET_ACCOUNT_VERIFICATION_REQUESTED", true);
          notification.success(
            "Profile Verification request sent to Seamless Source"
          );
        })
        .catch((error) => {
          notification.error("Something went wrong, please try again later.");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.parent-container {
  margin-top: 10px;
  width: 70%;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}
.container-content {
  width: 500px;
}
.md-field.md-theme-default.md-focused label {
  color: $tableBorder !important;
}
.md-field.md-theme-default:before {
  background-color: $tableBorder !important;
}
.selection-field::v-deep .v-select__selections {
  color: $tableBorder;
}
.list-content {
  color: $tableBorder !important;
}
.theme--light.v-sheet {
  background-color: #f3edf7 !important;
}
.v-text-field__details {
  padding: 0px !important ;
}
a {
  color: #442d65 !important;
}
.text-color::v-deep .v-label {
  color: $tableBorder !important;
}
.organization-request-label::v-deep .span-label-container {
  margin-top: -25px;
  text-align: end;
  margin-right: 5px;
  z-index: auto;
  position: relative;
}
.verification-alert-container > div {
  padding: 0px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.verification-alert-container div {
  color: $dangerBackground;
}
</style>
